/* eslint-disable linebreak-style */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable operator-linebreak */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable no-return-await */
// eslint-disable-next-line no-unused-vars
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  orderBy,
  addDoc,
  updateDoc,
} from 'firebase/firestore/lite';
import { Timestamp } from 'firebase/firestore';
import { db } from './firebase';

// Categorized all Products by their category
export const getCategorizedProducts = async (productCategories, allProducts) => {
  const finalData = [];
  productCategories.forEach((prod) => {
    const dataByCategory = allProducts?.filter((item) => item.categoryId === prod);
    const datasortProduct = dataByCategory?.sort((a, b) => a.position - b.position);
    const dataObj = {
      categoryId: prod,
      products: datasortProduct,
    };
    finalData.push(dataObj);
  });

  const data = await finalData?.map(async (item) => {
    const catRef = doc(db, 'categories', item.categoryId);
    const catDoc = await getDoc(catRef);

    const categoryData = await catDoc.data();
    return {
      ...item,
      categoryName: categoryData?.name,
      position: categoryData?.position,
      status: categoryData?.status,
    };
  });

  return await Promise.all(data).then((res) => res);
};

// eslint-disable-next-line no-shadow
const getArrayFromCollection = (collection) => collection.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

export const createGuest = async (value, collections, param) => {
  localStorage.setItem('user', value?.phone);
  const colRef = collection(db, collections);
  const result = await getDocs(query(colRef, where(param, '==', value?.phone)));
  const resultDataUser = getArrayFromCollection(result);
  if (resultDataUser.length === 0) {
    localStorage.setItem('user', value?.phone);
    const data = await addDoc(collection(db, collections), {
      ...value,
    });
    return data;
  }
};

export const createNewGuestsDuplicateNumber = async (obj, collections) => {
  const data = await addDoc(collection(db, collections), {
    ...obj,
  });
  return data;
};

export const wellcomeUser = async (userPhone, restaurant) => {
  try {
    const fetchCollection = collection(db, 'guests');
    const fetchUser = await getDocs(query(fetchCollection, where('phone', '==', userPhone)));
    const user = getArrayFromCollection(fetchUser);
    if (user.length !== 1) {
      const filter = user.filter((item) => item?.restaurant === restaurant);
      return filter;
    }
    return user;
  } catch (err) {
    console.log(err);
  }
};

export const validateGuest = async (value, collections, param, url, token) => {
  try {
    const time = Timestamp.fromDate(new Date()).toDate();
    const colRefGuests = collection(db, collections);
    const colRefRestaurant = collection(db, 'restaurants');

    // USER
    const result = await getDocs(query(colRefGuests, where(param, '==', value)));
    const resultDataUser = getArrayFromCollection(result);

    // OWNER
    const restaurantOwner = await getDocs(query(colRefRestaurant, where('shortUrl', '==', url)));
    const DataOwner = getArrayFromCollection(restaurantOwner);

    const filterRestaurants = resultDataUser.filter(
      (item) => item?.restaurantId === DataOwner[0]?.id,
    );

    if (filterRestaurants.length === 0) {
      const infoUser = {
        name: resultDataUser[0]?.name,
        birthdate: '',
        blackListed: false,
        email: '',
        vip: false,
        lastVisit: time,
        firstVisit: time,
        signInType: 'web',
        phone: value,
        numberOfVisits: 1,
        token: token || '',
        restaurant: DataOwner[0]?.shortUrl,
        restaurantId: DataOwner[0]?.id,
      };
      const response = await createNewGuestsDuplicateNumber(infoUser, 'guests');
      return response;
    }

    if (filterRestaurants.length !== 0) {
      const timeGeneral = 30000;

      // Data time by user
      const userDate = filterRestaurants[0]?.lastVisit?.seconds * 1000;
      const lastVisitDate = new Date(userDate).toString().split(' ', 5);
      const monthDate = new Date(userDate).getMonth();
      const dayDate = Number(lastVisitDate[2]);
      const yearDate = Number(lastVisitDate[3]);
      const hoursDate = Number(lastVisitDate[4].split(':', 3).join('').toString());

      // Data today time
      const today = new Date();
      const lastVisit = new Date(today).toString().split(' ', 5);
      const month = new Date(userDate).getMonth();
      const day = Number(lastVisit[2]);
      const year = Number(lastVisit[3]);
      const hours = Number(lastVisit[4].split(':', 3).join('').toString());

      const timeComparison = hours - hoursDate;

      if (
        (dayDate === day &&
          monthDate === month &&
          yearDate === year &&
          Math.sign(timeComparison) === 1 &&
          timeComparison >= timeGeneral) ||
        dayDate !== day
      ) {
        const visitUpdate = filterRestaurants[0]?.numberOfVisits + 1;
        const objGuest = {
          ...filterRestaurants[0],
          numberOfVisits: visitUpdate,
          token: token || '',
          lastVisit: time,
          restaurant: DataOwner[0]?.shortUrl,
        };
        const response = await updateDoc(doc(colRefGuests, filterRestaurants[0]?.id), objGuest);
        return response;
      }
    }
  } catch (err) {
    console.log(err);
  }
};

// CREATE NEW OBJECT IN COLLECTION
export const createItemCustom = async (obj, collections) => {
  try {
    const colRef = collection(db, collections);
    const dataUser = await getDocs(query(colRef, where('name', '==', 'test')));
    getArrayFromCollection(dataUser);
  } catch (err) {
    throw new Error('phone is required');
  }
  /* const data = await addDoc(collection(db, collections), {
    ...obj,
  }); */
  // return data;
};

// Get Restaurant by Short URL
export const getRestaurantByUrl = async (
  url,
  setRestaurant,
  setLoadData,
  check,
  setRestaurantUser,
) => {
  try {
    const docs = query(collection(db, 'restaurants'), where('shortUrl', '==', url));
    const restaurantDoc = await getDocs(docs);
    const data = restaurantDoc.docs?.map((docum) => ({ id: docum?.id, ...docum.data() }));
    if (data?.length) {
      setRestaurant(data?.[0]);
      setRestaurantUser(data?.[0]);
      check && setLoadData(true);
    } else {
      setLoadData(true);
    }
  } catch (err) {
    throw new Error('restaurant not found');
  }
};

export const updateItem = async (id, obj, collections) => {
  const colRef = collection(db, collections);
  await updateDoc(doc(colRef, id), obj);
};

export const getItemsByCondition = async (value, collections, param) => {
  const colRef = collection(db, collections);
  const result = await getDocs(query(colRef, where(param, '==', value)));
  return getArrayFromCollection(result);
};

export const getProducts = async (
  setLoading,
  setProducts,
  restaurantId,
  setLoadData,
  setAllProducts,
) => {
  const docs = query(
    collection(db, 'products'),
    where('deleted', '==', false),
    where('enabled', '==', true),
    where('restaurantId', '==', restaurantId),
  );
  setLoading(true);
  const productDocs = await getDocs(docs);
  const allProducts = productDocs?.docs?.map((docum) => ({ id: docum.id, ...docum.data() }));
  if (allProducts?.length) {
    const allCategories = allProducts?.map((item) => item.categoryId);

    const uniqueCategories = [...new Set(allCategories)];

    // const limitCategories = uniqueCategories?.filter((cat, index) => index < 5);

    // const dataWithCategory = await getCategorizedProducts(limitCategories, allProducts);

    const allDataWithCategory = await getCategorizedProducts(uniqueCategories, allProducts);
    const dataWithCategory = allDataWithCategory
      ?.filter((cat, index) => index < 5)
      .sort((a, b) => a.position - b.position);
    // const newArray = [];
    // eslint-disable-next-line array-callback-return
    // dataWithCategory.map((item) => {
    //   if (item.catagoryName === 'Vintage Champagne') {
    //     newArray.push(item);
    //   }
    // });
    const filter = dataWithCategory?.filter((item) => item?.status === true);
    setAllProducts(allDataWithCategory);
    setProducts(filter);
    setLoadData(true);
  }
  setLoadData(true);
};

export const getAllSubCategories = async (selectedMenu, setCategories, restaurantId) => {
  const docs = query(
    collection(db, 'categories'),
    where('restaurantId', '==', restaurantId),
    where('status', '==', true),
    orderBy('position', 'asc'),
  );
  const cats = await getDocs(docs);
  const data = cats?.docs?.map((docum) => ({ id: docum?.id, ...docum.data() }));
  setCategories(data);
  // setLoadData(true);
};

export const getAllMenus = async (setMenus, restaurantId) => {
  const docs = query(
    collection(db, 'menus'),
    where('restaurantId', '==', restaurantId),
    orderBy('position', 'asc'),
  );
  const menuDocs = await getDocs(docs);
  const data = menuDocs.docs?.map((docum) => ({ id: docum?.id, ...docum.data() }));
  const filterData = data.filter((item) => item?.status === true);
  setMenus(filterData);
  // setLoadData(true);
};

export const getProductsByMenu = async (selectedMenu, setLoading, setProducts, setLoadData) => {
  setLoading(true);
  setLoadData(false);
  const productQuery = query(
    collection(db, 'products'),
    where('deleted', '==', false),
    where('enabled', '==', true),
    where('menuId', '==', selectedMenu?.id),
  );
  const productsData = await getDocs(productQuery);

  const allProducts = productsData?.docs?.map((docum) => ({ id: docum?.id, ...docum.data() }));
  if (allProducts?.length > 0) {
    const allCategories = allProducts?.map((item) => item.categoryId);
    const uniqueCategories = [...new Set(allCategories)];

    const dataWithCategory = await getCategorizedProducts(uniqueCategories, allProducts);
    const orderData = dataWithCategory.sort((a, b) => a.position - b.position);
    const filter = orderData?.filter((item) => item?.status === true);
    setProducts(filter);
    setLoadData(true);
    // setLoading(false);
    return;
  }
  setLoadData(true);
  setProducts([]);
  // setLoading(false);
};

export const getProductsByCategory = async (
  selectedCategory,
  setLoading,
  setProducts,
  setLoadData,
) => {
  setLoading(true);
  setLoadData(false);
  const productQuery = query(
    collection(db, 'products'),
    where('deleted', '==', false),
    where('enabled', '==', true),
    where('categoryId', '==', selectedCategory?.id),
  );
  const productsData = await getDocs(productQuery);
  const allProducts = productsData?.docs?.map((docum) => ({ id: docum?.id, ...docum.data() }));
  const data = [
    {
      categoryName: selectedCategory?.name,
      position: selectedCategory?.position,
      categoryId: selectedCategory?.id,
      products: allProducts,
    },
  ];
  const dataSort = data.sort((a, b) => a.position - b.position);
  setProducts(dataSort);
  setLoadData(true);
  // setLoading(false);
};

//    Searched Products
export const getSearchedProducts = async (
  searchTerm,
  setLoading,
  setProducts,
  restaurantId,
  setLoadData,
) => {
  setLoadData(false);
  if (searchTerm) {
    const searchText = searchTerm.toLowerCase();
    setLoading(true);
    const productQuery = query(
      collection(db, 'products'),
      where('deleted', '==', false),
      where('enabled', '==', true),
      where('restaurantId', '==', restaurantId),
    );
    const productsData = await getDocs(productQuery);
    let allProducts = productsData?.docs?.map((docum) => ({
      id: docum?.id,
      ...docum.data(),
    }));

    allProducts = allProducts.filter(
      (a) =>
        // eslint-disable-next-line implicit-arrow-linebreak
        a.name?.toLowerCase().includes(searchText) ||
        a.description?.toLowerCase().includes(searchText),
    );
    if (allProducts?.length > 0) {
      const allCategories = allProducts?.map((item) => item.categoryId);
      const uniqueCategories = [...new Set(allCategories)];

      const limitCategories = uniqueCategories?.filter((cat, index) => index < 5);

      const dataWithCategory = await getCategorizedProducts(limitCategories, allProducts);
      setProducts(dataWithCategory);
      setLoadData(true);
      // setLoading(false);
      return;
    }
    setLoadData(true);
    // setLoading(false);
    setProducts([]);
  } else {
    getProducts(setLoading, setProducts, restaurantId, setLoadData);
  }
};

export const getUserbyPhone = async (value) => {
  const colRef = collection(db, 'guests');
  const result = await getDocs(query(colRef, where('phone', '==', value)));
  return getArrayFromCollection(result);
};

export const getRestaurant = async (value) => {
  const colRef = collection(db, 'restaurants');
  const result = await getDocs(query(colRef, where('shortUrl', '==', value)));
  return getArrayFromCollection(result);
};

export const getLinksRestaurant = async (value) => {
  const colRef = collection(db, 'linksRestaurant');
  const result = await getDocs(query(colRef, where('restaurantId', '==', value)));
  return getArrayFromCollection(result);
};

export const updateLikeProductUser = async (id, obj, collections) => {
  const colRef = collection(db, collections);
  await updateDoc(doc(colRef, id), obj);
};

export const getProductsByCondition = async (value, collections, param) => {
  const colRef = collection(db, collections);
  const result = await getDocs(query(
  colRef,
    where(param, '==', value),
    ));
  return getArrayFromCollection(result);
};
export const getProductsBuy = async (value, collections, param, restaurant) => {
  const colRef = collection(db, collections);
  const result = await getDocs(query(
  colRef,
    where(param, '==', value),
    where('restaurantId', '==', restaurant),
    ));
  return getArrayFromCollection(result);
};

export const getItemByIdCustom = async (id, collections) => {
  const colRef = collection(db, collections);
  const result = await getDoc(doc(colRef, id));
  return { ...result?.data(), id: result?.id };
};

/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-unused-vars */
import {
  createContext, useContext, useState, useEffect,
} from 'react';
import {
  RestaurantService, MenuService, ProductService, UserService,
} from '../services';

export const authContext = createContext();

export const useAuth = () => {
  const context = useContext(authContext);
  if (!context) throw new Error('There is not auth provider');
  return context;
};

export function AuthProvider({ children }) {
  const [userGuest, setUserGuest] = useState(true);
  const [restaurantUser, setRestaurantUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [newLike, setNewLike] = useState(false);
  const [textLoad, setTextLoad] = useState('');

  useEffect(() => {
    const getDataUser = async () => {
      const phoneUser = localStorage.getItem('user');
      const [dataUser] = await UserService.getUser(phoneUser);

      if (dataUser?.buyProducts && dataUser?.buyProducts?.length !== 0) {
        setNewLike(true);
      }
    };
    getDataUser();
  }, [newLike]);

  return (
    <authContext.Provider
      value={{
        userGuest,
        setUserGuest,
        loading,
        restaurantUser,
        setRestaurantUser,
        setTextLoad,
        textLoad,
        newLike,
        setNewLike,
      }}
    >
      {children}
    </authContext.Provider>
  );
}

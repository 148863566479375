/* eslint-disable linebreak-style */
// main tools
import React from 'react';

// bootstrap
import { Container, Row, Col } from 'react-bootstrap';
import FavoriteButton from '../FavoriteButton';

import styles from './styles.module.css';

function FavoriteProductCar({ handleSelectProduct, prod }) {
  return (
    <Container className={styles.container}>
      <Row className="p-3 align-items-center">
        <Col xs={7}>
          <FavoriteButton product={prod} />
          <div aria-hidden="true" onClick={handleSelectProduct}>
            <h4 className={styles.title}>{prod?.name}</h4>
            <p className={styles.desc}>{prod?.description || 'Not description provided'}</p>
            <p className={styles.price}>
              $
              {prod?.price}
            </p>
          </div>
        </Col>
        <Col xs={5} onClick={handleSelectProduct}>
          <img src={prod?.image} alt={prod?.name} className={styles.img} />
        </Col>
      </Row>
    </Container>
  );
}
export default FavoriteProductCar;

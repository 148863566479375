/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from 'react';
import { getProductsBuy, getRestaurant } from '../../../utils/getData';
import './BasketItem.css';

function BasketItem({
  id, name, quantity, updateQuantity, data, handleChange, restaurant,
}) {
  const [product, setProduct] = useState();

  useEffect(() => {
    const reorderProducts = async () => {
      const [result] = await getProductsBuy(data?.name, 'products', 'name', restaurant);
      setProduct(result);
    };
    reorderProducts();
  }, [data?.name, restaurant]);

  return (
    <div className="product">
      <img className="product-img" src={product?.image} alt={name} />
      <div className="product-body">
        <h3 style={{
          height: '31%',
          width: '74%',
          marginBottom: '4vw',
          fontFamily: 'Metropolis-bold',
          textTransform: 'uppercase',
        }}
        >
          {product?.name}
        </h3>
        <h4 className="price" title={product?.name}>
          $
          {product?.price}
        </h4>
        <div className="actions">
          <span
            href="#0"
            className="delete-item"
            onClick={() => {
              handleChange(product);
            }}
          >
            Delete
          </span>
          <div
            className="quantity"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              alignItems: 'flex-end',
            }}
          >
            <h4
              htmlFor="cd-product-8"
              style={{
                marginRight: '1vw',
                fontFamily: 'Metropolis',
              }}
            >
              Quantity
            </h4>
            <span className="select">
              <select
                id="cd-product-8"
                name="quantity"
                style={{
                  backgroundColor: 'black',
                  color: 'white',
                  borderRadius: '4px',
                  fontFamily: 'Metropolis-bold',
                  textTransform: 'uppercase',
                }}
                onChange={(e) => {
                  updateQuantity(data, data?.id, e?.target?.value);
                }}
                defaultValue={data?.quantity}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
              </select>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BasketItem;

// Init
import React from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';
import { ToastContainer, Flip } from 'react-toastify';

// styles
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'primereact/resources/primereact.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-phone-number-input/style.css';

// Files
import Index from './routes/index';
import './css/index.css';

function App() {
  return (
    <div className="app">
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
        {/* toastify Container for Notification */}
        <ToastContainer
          hideProgressBar
          autoClose={3000}
          transition={Flip}
          position="bottom-right"
        />

        {/* Routes */}
        <Index />
      </SkeletonTheme>
    </div>
  );
}

export default App;

/* eslint-disable linebreak-style */
// Init
import { Routes, Route } from 'react-router-dom';
import React from 'react';

// components
import VerifyUrl from '../components/VerifyUrl';
import ProtectedRoute from './ProtectedRouter';

// providers
import { AuthProvider } from '../context/authContext';

// Routes
// import Home from '../containers/Home';
// import SignInPhone from '../containers/SignInPhone';
import GuestRegister from '../containers/GuestRegister';
import Favorites from '../containers/Favorites';
import Menu from '../containers/Menus';

function Index() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="*" element={<VerifyUrl />} />
        <Route path="/:restaurant" element={<GuestRegister />} />
        <Route path="/:restaurant/:token" element={<GuestRegister />} />
        <Route path="/favorites" element={<Favorites />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/:id/menu" element={<Menu />} />
        </Route>
      </Routes>
    </AuthProvider>
  );
}
export default Index;

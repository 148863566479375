/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable indent */
/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable linebreak-style */
import React from 'react';
import { useParams } from 'react-router-dom';
import { DivMotion, fadeIn } from './utils';
import FavoriteButton from '../FavoriteButton';
import BuyButton from '../BuyButton';

// styles
import classes from './styles.module.css';

function ProductCard({
  handleSelectProduct,
  showFavProducts,
  product,
  changeBuyProd,
  setChangeBuyProd,
  selectedMenu,
}) {
  const params = useParams();

  const { initial, viewport, transition, whileInView } = fadeIn;

  return (
    <>
      {(!selectedMenu.viewList) ? (
        <DivMotion
          initial={initial}
          aria-hidden="true"
          viewport={viewport}
          transition={transition}
          whileInView={whileInView}
          className={classes.container}
          onKeyDown={handleSelectProduct}
        >
          <img
            width="100%"
            aria-hidden="true"
            alt={product.name}
            src={product.image}
            className={classes.img}
            onClick={handleSelectProduct}
          />
          <div className={classes.content}>
            {params?.id === 'tu-candelita' ? (
              <BuyButton
                showFavProducts={showFavProducts}
                product={product}
                changeBuyProd={changeBuyProd}
                setChangeBuyProd={setChangeBuyProd}
              />
            ) : (
              <FavoriteButton showFavProducts={showFavProducts} product={product} />
            )}
            <div
              aria-hidden="true"
              onClick={handleSelectProduct}
              className={classes.containerDetails}
            >
              <h4 className={classes.title}>{product.name}</h4>
              <span title={product.description} className={classes.desc}>
                {product.description || 'Not description provided'}
              </span>
              <p className={classes.price}>${product.price}</p>
            </div>
          </div>
        </DivMotion>
      ) : (
        <DivMotion
          initial={initial}
          aria-hidden="true"
          viewport={viewport}
          transition={transition}
          whileInView={whileInView}
          className={classes.containerList}
          onClick={handleSelectProduct}
        >
          <span className={classes.productName}>{product.name}</span>
          <span className={classes.productPrice}>${product.price}</span>
        </DivMotion>
      )}
    </>
  );
}
export default ProductCard;

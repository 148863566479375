/* eslint-disable linebreak-style */
// main tools
import React, { useState, useEffect } from 'react';

// prime components
import { Sidebar } from 'primereact/sidebar';

// bootstrap components
import { Col, Container, Row } from 'react-bootstrap';

// mui components
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

// local components
import FavoriteProductCar from '../FavoriteProductCard';
import ProductPreview from '../ProductPreview';
import Footer from '../Footer';
import Empty from '../Empty';

// icons
import starSvg from '../../assets/icons/Icon.svg';

// services
import { ProductService, UserService } from '../../services';

// styles
import styles from './styles.module.css';

function FavoritesMenu({ restaurant, onHide, visible }) {
  const [favoriteProduct, setFavoriteProduct] = useState();
  const [products, setProducts] = useState();

  const handleSelectProduct = (prod) => {
    setFavoriteProduct(prod);
  };

  useEffect(() => {
    (async () => {
      const phoneUser = localStorage.getItem('user');
      const [dataUser] = await UserService.getUser(phoneUser);

      if (dataUser?.phone && dataUser?.phone === phoneUser) {
        const data = await ProductService.getFavProducts(restaurant?.id, dataUser.likeProducts);
        setProducts(data);
      } else setProducts([]);
    })();
  }, [restaurant, visible]);

  return (
    <>
      <Sidebar
        appendTo="self"
        position="bottom"
        maskClassName={styles.sideBar}
        visible={!favoriteProduct && visible}
        style={{ backgroundColor: restaurant?.shortUrl === 'la-biblioteque' ? '#1E2E5F' : 'black' }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col sm={9} md={8} lg={6} xs={12}>
              <div className={styles.header}>
                <ChevronLeftIcon
                  onClick={onHide}
                  sx={{ fontSize: 45 }}
                  className={styles.iconLeft}
                />
                <img src={restaurant?.logo} alt="logo" className={styles.logo} />
              </div>
              <div className={styles.containerTitle}>
                <img src={starSvg} alt="IconStar" />
                <h4 className={styles.title}>Favorites</h4>
              </div>
              {!!products
                && (products.length > 0 ? (
                  products.map((prod) => (
                    <FavoriteProductCar
                      prod={prod}
                      key={prod?.id}
                      handleSelectProduct={() => handleSelectProduct(prod)}
                    />
                  ))
                ) : (
                  <Empty
                    title="No favorites yet"
                    desc={[
                      'Tap on the heart to add your favorites!',
                      'Add items to your favs and see them here.',
                    ]}
                  />
                ))}

              <div>
                <Footer logo={restaurant?.logo} />
              </div>
            </Col>
          </Row>
        </Container>
      </Sidebar>

      <ProductPreview
        productIdx={0}
        restaurant={restaurant}
        visible={favoriteProduct}
        selectedProduct={favoriteProduct}
        handleClosePreview={() => setFavoriteProduct(undefined)}
      />
    </>
  );
}
export default FavoritesMenu;

/* eslint-disable linebreak-style */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import { getProductsByCondition } from '../utils/getData';
import ImageProduct from '../assets/icons/imageProduct.svg';
import Heart from '../assets/icons/heart.svg';

export const Card = styled('div')`
  background: #0d0d0d;
  width: 80%;
  border-radius: 8px;
  height: 25%;
  padding: 1.5rem;
  margin-bottom: 1rem;
  margin-left: 4.5rem;
  display: flex;
  justify-content: space-between;
`;

export const ContainFirst = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const ProductTitle = styled('p')`
  font-weight: 500;
  font-size: 14px;
  margin-top: 1.5rem;
  font-family: 'Metropolis';
  height: 50%;
  width: 70%;
  color: #f2f2f2;
  line-height: 122.5%;
  opacity: 0.8;
`;

export const ProductDescription = styled('p')`
  font-weight: 500;
  height: 50%;
  width: 70%;
  margin-top: 2rem;
  font-size: 10px;
  font-family: 'Metropolis' !important;
  color: #f2f2f2f2;
`;

export const ProductPrice = styled('p')`
  color: #cdd3d6;
  font-weight: 600;
  font-size: 18px;
  font-family: 'Metropolis';
`;

export default function CardFavorites({ item, dataUser }) {
  const [products, setProducts] = useState();

  useEffect(() => {
    const reorderProducts = async () => {
      const result = await getProductsByCondition(item?.name, 'products', 'name');
      setProducts(result[0]);
    };
    reorderProducts();
  }, [item]);

  return (
    <Card>
      <ContainFirst>
        <Box component="img" src={Heart} style={{ width: 25, height: 25, marginRight: '1rem' }} />
        <ProductTitle>{products?.name}</ProductTitle>
        <ProductDescription>{products?.description}</ProductDescription>
        <ProductPrice>
          $
          {products?.price}
        </ProductPrice>
      </ContainFirst>
      <Box
        component="img"
        src={products?.image}
        style={{
          width: '35%',
          objectFit: 'cover',
          backgroundSize: 'cover',
          borderRadius: 10,
        }}
      />
    </Card>
  );
}

/* eslint-disable linebreak-style */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
// import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Stars from '../assets/icons/stars-02.svg';
import CardFavorites from '../components/CardFavorites';
import { getUserbyPhone, getItemsByCondition } from '../utils/getData';
import '../css/ProductSection.css';
import '../css/index.css';

export const ContainFavorites = styled('div')`
  background: #000;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
`;

export const StarsIcon = styled(Box)`
  width: 25px;
  height: 25px;
  margin-right: 3rem;
  opacity: 0.8;
`;

export const InputCustom = styled(OutlinedInput)`
  text-align: right;
  .MuiOutlinedInput-input {
    text-align: right;
    color: #cdd3d6;
  }
`;

export const ContainNavigation = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 3.5rem;
  margin-right: 3.5rem;
  margin-left: 3.5rem;
`;

export const ContainTitle = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 3rem 3rem 0rem 4rem;
`;

export const Title = styled('p')`
  font-family: 'Metropolis';
  font-size: 32px;
  font-weight: 600;
  line-height: 32px;
  color: #f2f2f2;
  opacity: 0.8;
  letter-spacing: 3px;
`;

export const ContainSearch = styled('div')`
  display: flex;
  justify-content: center;
`;

export const ProductDescription = styled('p')`
  font-weight: 500;
  height: 100%;
  width: 100%;
  margin-top: 2rem;
  font-size: 20px;
  font-family: 'Metropolis' !important;
  color: #f2f2f2f2;
  text-align-last: center;
`;

export default function Favorites() {
  const navigate = useNavigate();
  const [text, setText] = useState('');
  const [dataUser, setDataUser] = useState([]);
  const [logo, setLogo] = useState();
  const restaurant = localStorage.getItem('restaurant');
  const phoneUser = localStorage.getItem('user');

  useEffect(() => {
    const fetchUserGuests = async () => {
      const fetchUser = await getUserbyPhone(phoneUser);
      const listDataLike = [];
      if (fetchUser) {
        const filterRestaurant = fetchUser?.filter((item) => item?.restaurant === restaurant);
        filterRestaurant[0]?.likeProducts?.forEach((item) => listDataLike.push(item));
        setDataUser(filterRestaurant);
      }
    };

    const fetchRestaurant = async () => {
      const fetchUser = await getItemsByCondition(restaurant, 'restaurants', 'shortUrl');
      if (fetchUser) {
        setLogo(fetchUser[0]?.logo);
      }
    };
    fetchUserGuests();
    fetchRestaurant();
  }, []);

  return (
    <ContainFavorites>
      <ContainNavigation>
        <IconButton onClick={() => navigate(-1)} style={{ width: 20, height: 20 }}>
          <ArrowBackIosNewOutlinedIcon style={{ color: '#FFFFFFB2', width: 28, height: 28 }} />
        </IconButton>
        <Avatar style={{ height: 48, width: 48, background: '#fff' }} src={logo || ''} />
      </ContainNavigation>
      <ContainTitle>
        <StarsIcon component="img" src={Stars} />
        <Title>Favorites</Title>
      </ContainTitle>
      {/* <ContainSearch>
        <InputCustom
          style={{
            fontFamily: 'Metropolis',
            color: '#CDD3D6',
            backgroundColor: 'rgba(255, 255, 255, 0.12)',
            marginleft: 8,
            marginRight: 8,
            marginTop: 15,
            marginBottom: 15,
            borderRadius: 5,
            width: '80%',
            height: 40,
            fontSize: 16,
            fontWeight: 500,
          }}
          onChange={(e) => setText(e?.target?.value)}
          placeholder="Search Here"
          value={text}
          startAdornment={(
            <InputAdornment position="start" style={{ color: '#fff' }}>
              <SearchOutlinedIcon style={{ width: 22, height: 22 }} />
            </InputAdornment>
          )}
        />
      </ContainSearch> */}
      {!dataUser[0]?.likeProducts || dataUser[0]?.likeProducts?.length === 0 ? (
        <div style={{ overflowX: 'hidden', overflowY: 'auto', textAlign: 'center' }}>
          <ProductDescription>
            You have not added products to your favorites list
          </ProductDescription>
        </div>
      ) : (
        dataUser[0]?.likeProducts?.map((item) => (
          <div style={{ overflowX: 'hidden', overflowY: 'auto' }}>
            <CardFavorites item={item} key={item?.name} dataUser={dataUser} />
          </div>
        ))
      )}
    </ContainFavorites>
  );
}

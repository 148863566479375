/* eslint-disable linebreak-style */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getProductsBuy, getRestaurant } from '../../../../utils/getData';
import BasketItem from '../index';
import { useAuth } from '../../../../context/authContext';
import { UserService, ProductService } from '../../../../services';
import './Basket.css';

function Basket({
  cartIsShown,
  itemCount,
  cart,
  deleteItem,
  updateQuantity,
  total,
}) {
  const [showIndicator, setShowIndicator] = useState(true);
  const [cartIsOpen, setCartIsOpen] = useState(false);
  const [products, setProducts] = useState();
  const [restaurant, setRestaurant] = useState();
  const [like, setLike] = React.useState();
  const [reset, setReset] = useState(false);
  const params = useParams();
  const { setNewLike } = useAuth();

  useEffect(() => {
    const getRestaurantConecte = async () => {
      const [data] = await getRestaurant(params?.id);
      setRestaurant(data?.id);
    };
    getRestaurantConecte();

    const reorderProducts = () => {
      const newArr = [];
      cart?.forEach(async (item) => {
        const result = await getProductsBuy(item?.name, 'products', 'name', restaurant);
        newArr.push(result);
      });
      setProducts(newArr);
      setReset(false);
    };
    reorderProducts();
  }, [cart, reset]);

  const handleClick = () => {
    setShowIndicator(!showIndicator);
    setCartIsOpen(!cartIsOpen);
  };

  const shouldShowIndicator = () => {
    if (itemCount > 0 && showIndicator) {
      return 'isShown';
    }
    return '';
  };

  const getDataUser = async () => {
    const phoneUser = localStorage.getItem('user');
    const [dataUser] = await UserService.getUser(phoneUser);

    if (dataUser?.phone && dataUser?.phone === phoneUser) {
      if (!dataUser.buyProducts) dataUser.buyProducts = [];
      return dataUser;
    }

    return null;
  };

  const handleChange = async (product) => {
    setLike(!like);

    const dataUser = await getDataUser();
    const updateLikedPosts = dataUser.buyProducts.filter(
      (likedProduct) => likedProduct.id !== product.id,
    );

    ProductService.updateLikedProducts({
      ...dataUser,
      buyProducts: updateLikedPosts,
    });
    setNewLike(false);
    setReset(true);
  };

  return (
    <section className={`${cartIsOpen ? 'cartIsOpen' : null}`}>
      <button
        className={`basket ${cartIsShown ? 'isShown' : null}`}
        onClick={handleClick}
        style={{
          position: 'fixed',
          bottom: '4.9vh',
          right: '1.5rem',
        }}
      >
        <span
          className={`counter ${shouldShowIndicator()}`}
        >
          {`${itemCount}`}
        </span>
        Basket
      </button>
      <article className="cart-container">
        <div className="cart-inner">
          {cart?.map((item, i) => (
            <BasketItem
              key={i}
              deleteItem={deleteItem}
              updateQuantity={updateQuantity}
              data={item}
              handleChange={handleChange}
              restaurant={restaurant}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...item}
            />
          ))}
        </div>
        <footer className="cart-footer">
          <span disabled className="checkout-button">
            $
            {total}
          </span>
        </footer>
      </article>
    </section>
  );
}

export default Basket;

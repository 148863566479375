/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-no-useless-fragment */
// main tools
import React from 'react';
import { useParams } from 'react-router-dom';

// prime components
import { Galleria } from 'primereact/galleria';
import { Sidebar } from 'primereact/sidebar';
import { Divider } from 'primereact/divider';
import { Image } from 'primereact/image';

// mui components
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

// bootstrap components
import {
  Col, Container, Row, Carousel,
} from 'react-bootstrap';

// components
import Footer from '../Footer';
import BuyButton from '../BuyButton';

// styles
import styles from './styles.module.css';

function ProductPreview(props) {
  const {
    categories = [],
    categoryIdx,
    productIdx,
    restaurant,
    selectedProduct,
    handleClosePreview,
    visible,
  } = props;
  const [activeIdx, setActiveIdx] = React.useState(productIdx);
  const params = useParams();
  const activePictureIdx = 0;

  const formatProducts = () => {
    if (categories[categoryIdx]?.products) {
      return categories[categoryIdx].products;
    }
    if (selectedProduct) {
      return [selectedProduct];
    }

    return [];
  };

  const handleFormatPictures = (product) => [
    {
      alt: product?.name,
      title: product?.name,
      itemImageSrc: product?.image,
      thumbnailImageSrc: product?.image,
    },
  ];

  const handleSlide = (slideToChange) => setActiveIdx(slideToChange);

  const handleFormatInfo = (product) => {
    const entries = Object.entries(product ?? {}).filter(
      (item) => ![
        'id',
        'name',
        'views',
        'price',
        'image',
        'menuId',
        'deleted',
        'enabled',
        'keyword',
        'category',
        'categoryId',
        'description',
        'restaurantId',
        'positionInCategory',
        'inventory',
      ].includes(item[0]) && item[1]?.toString()?.trim() !== '',
    );

    return entries;
  };

  React.useEffect(() => {
    setActiveIdx(productIdx);
  }, [productIdx]);

  const itemTemplate = (item) => (
    <Image preview src={item.itemImageSrc} alt={item.alt} className={styles.mainPicture} />
  );

  const thumbnailItem = (item) => (
    <img alt={item.alt} src={item.thumbnailImageSrc} className={styles.thumbnailPicture} />
  );

  const handleShowInfo = (product) => handleFormatInfo(product).length > 0 && (
  <Container>
      {handleFormatInfo(product).map(([key, value]) => (
        <>
          {key !== 'position' ? (
            <Row key={key}>
              {/* <Col xs={6}>
                <p className={styles.infoName}>
                  {key}
                  <span>:</span>
                </p>
              </Col>
              <Col xs={6}>
                <p className={styles.infoValue}>{value}</p>
              </Col> */}
            </Row>
          ) : (
            <></>
          )}
        </>
      ))}
  </Container>
  );

  return (
    <Sidebar
      appendTo="self"
      position="bottom"
      visible={visible}
      onHide={handleClosePreview}
      maskClassName={styles.sideBar}
      style={{ backgroundColor: restaurant?.shortUrl === 'la-biblioteque' ? '#1E2E5F' : 'black' }}
    >
      <Container>
        <Row className="justify-content-center">
          <Col sm={9} md={8} lg={6} xs={12}>
            <div className={styles.content}>
              <div className={styles.header}>
                <ChevronLeftIcon
                  sx={{ fontSize: 45 }}
                  className={styles.iconLeft}
                  onClick={handleClosePreview}
                />
                <img src={restaurant?.logo} alt={restaurant?.name} className={styles.logo} />
              </div>
              <Carousel
                interval={null}
                controls={false}
                indicators={false}
                onSelect={handleSlide}
                activeIndex={activeIdx}
              >
                {formatProducts().map((product) => {
                  console.log(product);
                  return (
                    <Carousel.Item key={product.id}>
                      <h4 className={styles.title}>{product.category?.name}</h4>
                      <div className={styles.galleryContainer}>
                        <Galleria
                          numVisible={3}
                          item={itemTemplate}
                          thumbnailsPosition="left"
                          thumbnail={thumbnailItem}
                          activeIndex={activePictureIdx}
                          value={handleFormatPictures(product)}
                          showThumbnails={handleFormatPictures(product).length > 1}
                        />
                        <div className={styles.containerInf}>
                          <h4 className={styles.titleProd}>{product.name}</h4>
                          <p className={styles.subtitle}>Product information</p>
                          <p className={styles.description}>
                            {product.description || 'Not description provided'}
                            {params?.id === 'tu-candelita' && <BuyButton product={product} />}
                          </p>
                          {/* <p className={styles.description}>
                            {product?.inventory?.[0]?.quantity !== '' && product?.inventory?.map((itemInventory) => (
                              <>
                                <p>
                                  {`${itemInventory?.quantity} ${itemInventory?.typeBottles} left`}
                                </p>
                              </>
                            ))}
                          </p> */}
                        </div>
                        {handleShowInfo(product)}
                        <Divider className={`mt-5 ${styles.divider}`} />
                        <p className={styles.priceProd}>
                          $
                          {product.price}
                        </p>
                      </div>
                      <div className={styles.containerFooter}>
                        <Footer logo={restaurant?.logo} />
                      </div>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </div>
          </Col>
        </Row>
      </Container>
    </Sidebar>
  );
}

export default ProductPreview;

// prime components
import { Skeleton as PrimeSkeleton } from 'primereact/skeleton';

// styles
import classes from './styles.module.css';

function Skeleton({ width = '100%', height = '100%' }) {
  return <PrimeSkeleton className={classes.skeleton} width={width} height={height} />;
}

export default Skeleton;

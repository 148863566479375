/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable object-curly-newline */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Box, Button } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import guestHome from '../assets/images/guestHome.png';
import guestHometwo from '../assets/images/guestHometwo.png';
// import logoHome from '../assets/images/logoHome.png';
import CircularProgress from '@mui/material/CircularProgress';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { useParams } from 'react-router-dom';
import { Timestamp, serverTimestamp, FieldValue } from 'firebase/firestore';
import { getItemsByCondition, createGuest, validateGuest, updateItem } from '../utils/getData';
import { Formik } from 'formik';
import * as yup from 'yup';

const Container = styled('div')`
  background-image: url('${guestHometwo}');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  @media (min-width: 700) {
  }
`;

const ContainerImage = styled('div')`
  background-image: url('${guestHome}');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  @media (min-width: 700) {
  }
`;

const MainContainer = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
`;

const ContainerLogo = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 9%;
  display: flex;
  flex-direction: column;
`;

const TextTitleRestaurant = styled('div')`
  position: relative;
  color: white;
  position: relative;
  font-weight: 700;
  font-size: 32px;
  line-height: 40.96px;
`;
const TextSubTitleRestaurant = styled('div')`
  position: relative;
  color: white;
  position: relative;
  font-weight: 400;
  font-size: 16px;
  line-height: 20.48px;
  display: flex;
  flex-direction: row;
`;

const LineSubTitleRight = styled('div')`
  width: 36.58px;
  background-color: white;
  height: 1px;
  position: absolute;
  top: 43%;
  right: 111%;
`;

const LineSubTitleLeft = styled('div')`
  width: 36.58px;
  background-color: white;
  height: 1px;
  position: absolute;
  top: 43%;
  left: 111%;
`;

const ContainerInitGust = styled('div')`
  background: white;
  width: 511px;
  height: 412px;
  border-radius: 30px;
  @media (min-width: 700px) {
    width: 511px;
  }
  @media (max-width: 699px) {
    width: 332px;
  }
`;

const ContainerTitle = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
`;

const TextTitle = styled('p')`
  font-family: Metropolis;
  width: 219px;
  text-align: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
`;
const TextSub = styled('p')`
  font-family: Metropolis;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #b4b4b4;
`;
const Divider = styled('div')({
  width: '300px',
  background: '#B4B4B436',
  color: '#B4B4B436',
  height: 1,
});

const TextValidationTest = styled('p')`
  font-size: 10px;
  font-weight: 500;
  font-family: 'Poppins';
  text-align: center;
  color: red;
  margin-left: 0.5rem;
  @media (max-width: 500px) {
    margin-top: 5px;
  }
`;

const validationSchema = yup.object().shape({
  name: yup.string().required('The name is required'),
});

function GuestRegister() {
  const replace = (path) => window.location.replace(path);
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState(null);
  const [dataLogo, setDataLogo] = useState(null);
  const [idRestaurant, setIdRestaurant] = useState(null);
  const initialValues = {
    name: '',
  };

  useEffect(() => {
    const fetchLogoRestaurant = async () => {
      try {
        const response = await getItemsByCondition(params?.restaurant, 'restaurants', 'shortUrl');
        setIdRestaurant(response[0]?.id);
        setDataLogo(response);
      } catch (err) {
        console.log(err);
      }
    };

    const validationGuest = async () => {
      try {
        const time = Timestamp.fromDate(new Date()).toDate();

        const infoUser = localStorage.getItem('user');
        if (infoUser) {
          await validateGuest(infoUser, 'guests', 'phone', params?.restaurant, params?.token);
          replace(`/${params?.restaurant}/menu`);
        }
      } catch (err) {
        console.log(err);
      }
    };
    validationGuest();
    fetchLogoRestaurant();
  }, []);

  // eslint-disable-next-line no-shadow
  const submitData = async (values, phone) => {
    try {
      const time = Timestamp.fromDate(new Date()).toDate();
      const response = await getItemsByCondition(params?.restaurant, 'restaurants', 'shortUrl');
      setLoading(true);
      const infoUser = {
        ...values,
        lastVisit: time,
        firstVisit: time,
        birthdate: '',
        blacklisted: false,
        email: '',
        phone,
        numberOfVisits: 1,
        restaurant: params?.restaurant,
        token: params?.token || '',
        restaurantId: idRestaurant,
      };

      const userExist = await getItemsByCondition(infoUser?.phone, 'guests', 'phone');
      const filtGuestUserExist = userExist.filter(
        (item) => item?.restaurantId === infoUser?.restaurantId && item?.phone === infoUser?.phone,
      );
      if (filtGuestUserExist.length !== 0) {
        const visitUpdate = filtGuestUserExist[0]?.numberOfVisits + 1;
        const newObj = {
          ...filtGuestUserExist[0],
          lastVisit: time,
          numberOfVisits: visitUpdate,
        };
        await updateItem(filtGuestUserExist[0]?.id, newObj, 'guests');
        localStorage.setItem('user', filtGuestUserExist[0]?.phone);
        replace(`/${params?.restaurant}/menu`);
        return;
      }
      await createGuest(infoUser, 'guests', 'phone');
      setLoading(false);
      replace(`/${params?.restaurant}/menu`);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container>
      <ContainerImage>
        {!dataLogo ? (
          <MainContainer />
        ) : (
          <MainContainer>
            <ContainerInitGust>
              <ContainerLogo>
                <Box
                  component="img"
                  src={dataLogo ? dataLogo[0]?.logo : ''}
                  style={{
                    position: 'absolute',
                    borderRadius: '235px',
                    width: '174px',
                    height: 'auto',
                    boxShadow: '5px 7px 19px',
                  }}
                />
              </ContainerLogo>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                // eslint-disable-next-line no-shadow
                onSubmit={(values) => {
                  submitData(values, phone);
                }}
              >
                {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
                  <div style={{ marginTop: '10.5rem' }}>
                    <ContainerTitle>
                      <TextTitle>Share your info with Restaurant</TextTitle>
                    </ContainerTitle>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '2rem',
                      }}
                    >
                      <Divider />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <PhoneInput
                        className="guestInput"
                        style={{
                          fontFamily: 'Metropolis',
                          color: '#B4B4B4',
                          border: '2px solid #B4B4B4',
                          backgroundColor: '#FFFF',
                          marginleft: 8,
                          marginRight: 8,
                          marginTop: 15,
                          marginBottom: 15,
                          borderRadius: 10,
                          width: 300,
                          height: 36,
                          fontSize: 12,
                        }}
                        defaultCountry="US"
                        placeholder="Phone Number"
                        value={phone}
                        onChange={(e) => setPhone(e)}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <OutlinedInput
                        style={{
                          fontFamily: 'Metropolis',
                          color: '#B4B4B4',
                          border: '1px solid #B4B4B4',
                          backgroundColor: '#FFFF',
                          marginleft: 8,
                          marginRight: 8,
                          marginTop: 15,
                          marginBottom: 15,
                          borderRadius: 10,
                          width: 300,
                          height: 36,
                          fontSize: 12,
                        }}
                        placeholder="Name"
                        value={values.name}
                        onChange={handleChange('name')}
                        onBlur={handleBlur('name')}
                        error={touched.name && errors.name ? true : undefined}
                      />
                    </div>
                    {errors.name && touched.name ? (
                      <TextValidationTest>{errors.name}</TextValidationTest>
                    ) : null}
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {loading ? (
                        <CircularProgress thickness={1} />
                      ) : (
                        <Button
                          style={{
                            width: 300,
                            borderRadius: 18,
                            background: '#171717',
                            border: '1px solid #B4B4B4',
                            textTransform: 'none',
                            color: '#fff',
                            fontFamily: 'Metropolis',
                            fontWeight: 500,
                            fontSize: 14,
                            marginRight: 8,
                            marginTop: 12,
                            textAlign: 'center',
                          }}
                          onClick={(e) => handleSubmit(e)}
                        >
                          Next
                        </Button>
                      )}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '1rem',
                      }}
                    >
                      <TextSub>Mynuu does not sell or share your data</TextSub>
                    </div>
                  </div>
                )}
              </Formik>
            </ContainerInitGust>
          </MainContainer>
        )}
      </ContainerImage>
    </Container>
  );
}

export default GuestRegister;

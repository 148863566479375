/* eslint-disable linebreak-style */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useNavigate, Outlet, useParams } from 'react-router-dom';
import { useAuth } from '../context/authContext';
import { getRestaurantByUrl } from '../utils/getData';

export default function ProtectedRoute() {
  const params = useParams();
  const {
    userGuest, setUserGuest, loading, setRestaurantUser,
  } = useAuth();
  const navigate = useNavigate();
  const [restaurant, setRestaurant] = useState({});

  const check = true;
  useEffect(() => {
    getRestaurantByUrl(params?.id, setRestaurant, setUserGuest, check, setRestaurantUser);
  }, [params?.id]);

  useEffect(() => {
    if (restaurant?.fontFamily) {
      document.querySelector('body').style.fontFamily = restaurant?.fontFamily;
    }
  }, [restaurant]);

  // eslint-disable-next-line max-len, jsx-a11y/heading-has-content
  if (loading) return <h1 />;
  setUserGuest(true);
  if (!userGuest) return navigate(`/${params?.id}`);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <Outlet />
    </>
  );
}

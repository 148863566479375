/* eslint-disable no-unused-vars */
import React from 'react';
import IconButton from '@mui/material/IconButton';
import LanguageIcon from '@mui/icons-material/Language';
import FacebookIcon from '@mui/icons-material/Facebook';
import { Divider } from 'primereact/divider';
import InstagramIcon from '@mui/icons-material/Instagram';
import TelegramIcon from '@mui/icons-material/Telegram';
import TwitterIcon from '@mui/icons-material/Twitter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LogoSvg from '../../assets/icons/logo.svg';
import styles from './style.module.css';

function Footer({ logo, data }) {
  const RRSS = data?.links?.[0];

  const goLink = (link) => {
    window.open(link, '_blank');
  };

  return (
    <div className={styles.container}>
      <div className={styles.footerContainer}>
        <img src={logo} alt="Restaurant Name!!" className={styles.restLogo} />

        {RRSS?.instagram && (
        <IconButton style={{ color: 'white' }} onClick={() => goLink(RRSS?.instagram)}>
          <InstagramIcon sx={{ fontSize: 18 }} />
        </IconButton>
        )}
        {RRSS?.facebook && (
        <IconButton style={{ color: 'white' }} onClick={() => goLink(RRSS?.facebook)}>
          <FacebookIcon sx={{ fontSize: 18 }} />
        </IconButton>
        )}
        {RRSS?.twitter && (
        <IconButton style={{ color: 'white' }} onClick={() => goLink(RRSS?.twitter)}>
          <TwitterIcon sx={{ fontSize: 18 }} />
        </IconButton>
        )}
        {RRSS?.whatsapp && (
        <IconButton style={{ color: 'white' }} onClick={() => goLink(RRSS?.whatsapp)}>
          <WhatsAppIcon sx={{ fontSize: 18 }} />
        </IconButton>
        )}
        {RRSS?.telegram && (
        <IconButton style={{ color: 'white' }} onClick={() => goLink(RRSS?.telegram)}>
          <TelegramIcon sx={{ fontSize: 18 }} />
        </IconButton>
        )}

      </div>

      <Divider className={`my-4 ${styles.divider}`} />

      <div className={styles.footer}>
        <p>Powered by</p>
        <img src={LogoSvg} alt="logo" />
      </div>
    </div>
  );
}

export default Footer;

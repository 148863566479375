// main tools
import React from 'react';

// Components Mui
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

// prime components
import { InputText } from 'primereact/inputtext';

// styles
import styles from './styles.module.css';

function Searcher({
  searching, searchValue, handleSearch, handleSearchChange,
}) {
  const input = React.useRef(null);

  React.useEffect(() => {
    if (searching && input?.current) {
      input?.current?.focus();
    }
  }, [searching, input]);

  return (
    <span className={searching ? styles.container : styles.container_hidden}>
      <SearchOutlinedIcon className={styles.searchIcon} onClick={handleSearch} />
      <InputText
        ref={input}
        value={searchValue}
        onBlur={handleSearch}
        placeholder="Search here"
        onChange={handleSearchChange}
        className={styles.searchInput}
      />
    </span>
  );
}

export default Searcher;

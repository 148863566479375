/* eslint-disable linebreak-style */
import {
  query, where, getDocs, collection,
} from 'firebase/firestore/lite';
import { db } from '../utils/firebase';

const UserService = {
  async getUser(phoneNumber) {
    try {
      const restaurant = window.localStorage.getItem('restaurant');

      const docs = query(
        collection(db, 'guests'),
        where('phone', '==', phoneNumber),
        where('restaurant', '==', restaurant),
      );

      const usersDocs = await getDocs(docs);
      const data = usersDocs.docs?.map((docum) => ({ id: docum?.id, ...docum.data() }));
      if (data.length) return data;

      return [];
    } catch (error) {
      return [];
    }
  },
};

export default UserService;

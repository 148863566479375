// main tools
import React from 'react';

// assets
import diamondPicture from '../../assets/images/diamond.png';

// styles
import styles from './styles.module.css';

function Empty({ title, desc }) {
  return (
    <div className={styles.container}>
      <img src={diamondPicture} alt="empty" />
      {title && <p className={styles.title}>{title}</p>}
      {desc && (
        <p className={styles.desc}>
          {typeof desc === 'string'
            ? desc
            : desc?.map((paragraph) => <span key={paragraph}>{paragraph}</span>)}
        </p>
      )}
    </div>
  );
}

export default Empty;

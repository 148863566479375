/* eslint-disable linebreak-style */
// main tools
import React from 'react';

// services
import { UserService } from '../../services';

// styles
import classes from './styles.module.css';

export default function Greetings({ showLogo = true, restaurant }) {
  const [user, setUser] = React.useState();

  React.useEffect(() => {
    (async () => {
      const phoneUser = localStorage.getItem('user');
      const [dataUser] = await UserService.getUser(phoneUser);

      if (dataUser?.phone && dataUser?.phone === phoneUser) setUser(dataUser);
      else setUser(null);
    })();
  }, []);

  return (
    <div className={classes.header}>
      {showLogo && (
        <img src={restaurant?.logo} className={classes.logo} alt={restaurant?.restaurantName} />
      )}
      <div className={classes.greetings}>
        {user && (
          <h4>
            Hello
            {` ${user.name}`}
            !
          </h4>
        )}
        <p>
          Welcome to
          {` ${restaurant?.restaurantName}`}
        </p>
      </div>
    </div>
  );
}

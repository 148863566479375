/* eslint-disable linebreak-style */
// main tools
import React from 'react';

// bootstrap
import { Spinner } from 'react-bootstrap';

// Context
import { useAuth } from '../../context/authContext';

// services
import { UserService, ProductService } from '../../services';

// styles
import classes from './styles.module.css';

function BuyButton({
  showFavProducts, product, changeBuyProd,
}) {
  const [like, setLike] = React.useState();
  const { setNewLike, newLike } = useAuth();

  const getDataUser = async () => {
    const phoneUser = localStorage.getItem('user');
    const [dataUser] = await UserService.getUser(phoneUser);

    if (dataUser?.phone && dataUser?.phone === phoneUser) {
      if (!dataUser.buyProducts) dataUser.buyProducts = [];
      return dataUser;
    }

    return null;
  };

  /**
   * Handle change like
   */
  const handleChange = async () => {
    setLike(!like);

    const newProd = {
      status: true, id: product.id, name: product.name, quantity: 1, price: product.price,
    };

    const dataUser = await getDataUser();
    setNewLike(dataUser);
    const updateLikedPosts = like
      ? dataUser.buyProducts.filter((likedProduct) => likedProduct.id !== product.id)
      : dataUser.buyProducts.concat(newProd);

    ProductService.updateLikedProducts({
      ...dataUser,
      buyProducts: updateLikedPosts,
    });
  };

  /**
   * Get user data
   */
  React.useEffect(() => {
    (async () => {
      const dataUser = await getDataUser();

      if (dataUser) {
        const likedProduct = dataUser?.buyProducts?.some((item) => item.id === product.id);

        setLike(likedProduct ?? null);
      } else setLike(null);
    })();
  }, [product.id, showFavProducts, changeBuyProd, newLike]);

  return (
    <div className={classes.conlike}>
      {like === undefined ? (
        <Spinner />
      ) : (
        <>
          <input
            title="like"
            checked={like}
            type="checkbox"
            className={classes.like}
            onChange={like !== null ? handleChange : undefined}
          />
          <div className={classes.checkmark}>
            <svg
              className={classes.outline}
              viewBox="0 0 1280 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1276 846c-8-18-24-30-44-30-6 0-12 2-18 4l-90 36-84-210c104-68 142-200 82-310L950 32c-10-20-32-32-56-32-8 0-16 2-24 4L640 94 410 4c-8-2-16-4-24-4-24 0-46 12-56 34L158 336c-60 110-22 242 82 310L156 856l-90-36c-6-2-12-4-18-4-18 0-36 12-44 30-10 24 2 52 26 62l272 112c6 2 12 4 18 4 18 0 36-12 44-30 10-24-2-52-26-62l-92-38 84-212c16 4 32 4 48 4 108 0 204-68 232-174l30-120v2-2l30 120c28 106 124 174 232 174 16 0 32 0 48-4l84 212-92 38c-24 10-36 38-26 62 8 18 26 30 44 30 6 0 12-2 18-4l272-112c24-10 36-38 26-62zM242 384l52-94 246 106-24 92c-50 202-374 76-274-104z m322-82l-222-96 58-102 194 76-30 122z m122-122l194-76 58 102-222 96-16-68-14-54z m78 308l-24-92 246-106 52 94c102 180-224 306-274 104z" />
            </svg>
            <svg className={classes.filled} viewBox="0 0 1279 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <path d="M638.687951 257.765811V100.32863L372.501386 2.953035c-22.747577-8.380686-47.889637 1.795861-58.864345 23.346198L143.42929 363.721632c-61.458367 121.719494-15.963212 266.984725 95.978814 334.030217l-55.871243 154.444079-119.325012-43.898834c-24.742979-8.979307-52.07998 3.791263-61.258827 28.733782s3.791263 52.47906 28.534242 61.458367l328.443093 120.322713c24.742979 8.979307 52.07998-3.791263 61.059287-28.733782s-3.791263-52.47906-28.534242-61.458367l-119.524552-43.699294 55.871243-154.244539c135.886845 23.146658 270.775988-65.44917 299.50977-206.723599l10.176548-49.485958 10.176548 49.485958c28.933322 141.274429 163.622926 229.870257 299.50977 206.723599l55.871243 154.244539-119.524552 43.699294c-24.742979 8.979307-37.513549 36.515848-28.534242 61.458367s36.316308 37.713089 61.059287 28.733782l328.443093-120.322713c24.742979-8.979307 37.513549-36.515848 28.534242-61.458367s-36.316308-37.713089-61.059287-28.733782l-119.524552 43.699294-55.871243-154.444079C1149.510744 630.506817 1194.806359 485.241586 1133.547532 363.522092L963.738861 26.299233C952.764153 4.549356 927.422553-5.427652 904.874516 2.953035L638.687951 100.32863V257.765811z m-71.235835 88.595828l-260.20036-95.380194 72.433076-143.46937 220.292329 80.614222-32.325505 158.235342z m142.27213 0l-32.325505-158.235342 220.292329-80.614222 72.433076 143.46937-260.20036 95.380194z" />
            </svg>
            <svg
              width="100"
              height="100"
              className={classes.celebrate}
              xmlns="http://www.w3.org/2000/svg"
            >
              <polygon points="10,10 20,20" className={classes.poly} />
              <polygon points="10,50 20,50" className={classes.poly} />
              <polygon points="20,80 30,70" className={classes.poly} />
              <polygon points="90,10 80,20" className={classes.poly} />
              <polygon points="90,50 80,50" className={classes.poly} />
              <polygon points="80,80 70,70" className={classes.poly} />
            </svg>
          </div>
        </>
      )}
    </div>
  );
}

export default BuyButton;

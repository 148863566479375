import { motion } from 'framer-motion';

const duration = 0.8;

export const DivMotion = motion('div');
export const fadeIn = {
  initial: { opacity: 0 },
  transition: { duration },
  viewport: { once: false },
  whileInView: { opacity: 1 },
};

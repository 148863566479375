/* eslint-disable linebreak-style */
// main tools
import React from 'react';

// bootstrap
import { Spinner } from 'react-bootstrap';

// services
import { UserService, ProductService } from '../../services';

// styles
import classes from './styles.module.css';

function FavoriteButton({ showFavProducts, product }) {
  const [like, setLike] = React.useState();

  const getDataUser = async () => {
    const phoneUser = localStorage.getItem('user');
    const [dataUser] = await UserService.getUser(phoneUser);

    if (dataUser?.phone && dataUser?.phone === phoneUser) {
      if (!dataUser.likeProducts) dataUser.likeProducts = [];
      return dataUser;
    }

    return null;
  };

  /**
   * Handle change like
   */
  const handleChange = async () => {
    setLike(!like);
    const newProd = { status: true, id: product.id, name: product.name };

    const dataUser = await getDataUser();

    const updateLikedPosts = like
      ? dataUser.likeProducts.filter((likedProduct) => likedProduct.id !== product.id)
      : dataUser.likeProducts.concat(newProd);

    ProductService.updateLikedProducts({
      ...dataUser,
      likeProducts: updateLikedPosts,
    });
  };

  /**
   * Get user data
   */
  React.useEffect(() => {
    (async () => {
      const dataUser = await getDataUser();

      if (dataUser) {
        const likedProduct = dataUser?.likeProducts?.some((item) => item.id === product.id);

        setLike(likedProduct ?? null);
      } else setLike(null);
    })();
  }, [product.id, showFavProducts]);

  return (
    <div className={classes.conlike}>
      {like === undefined ? (
        <Spinner />
      ) : (
        <>
          <input
            title="like"
            checked={like}
            type="checkbox"
            className={classes.like}
            onChange={like !== null ? handleChange : undefined}
          />
          <div className={classes.checkmark}>
            <svg viewBox="0 0 24 24" className={classes.outline} xmlns="http://www.w3.org/2000/svg">
              <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Zm-3.585,18.4a2.973,2.973,0,0,1-3.83,0C4.947,16.006,2,11.87,2,8.967a4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,11,8.967a1,1,0,0,0,2,0,4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,22,8.967C22,11.87,19.053,16.006,13.915,20.313Z" />
            </svg>
            <svg viewBox="0 0 24 24" className={classes.filled} xmlns="http://www.w3.org/2000/svg">
              <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Z" />
            </svg>
            <svg
              width="100"
              height="100"
              className={classes.celebrate}
              xmlns="http://www.w3.org/2000/svg"
            >
              <polygon points="10,10 20,20" className={classes.poly} />
              <polygon points="10,50 20,50" className={classes.poly} />
              <polygon points="20,80 30,70" className={classes.poly} />
              <polygon points="90,10 80,20" className={classes.poly} />
              <polygon points="90,50 80,50" className={classes.poly} />
              <polygon points="80,80 70,70" className={classes.poly} />
            </svg>
          </div>
        </>
      )}
    </div>
  );
}

export default FavoriteButton;

/* eslint-disable consistent-return */
// main tools
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

// Components Mui
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import MenuIcon from '@mui/icons-material/Menu';

// components
import Searcher from '../Searcher';

// Styles
import styles from './styles.module.css';

function Header({
  searchValue, handleSearchChange, handleShowChooseMenu, handleFavMenu,
}) {
  const [searching, setSearching] = useState(false);
  const params = useParams();

  const likeFavorite = useMemo(() => {
    if (!searching && params?.id !== 'tu-candelita') {
      return (
        <FavoriteBorderOutlinedIcon
          onClick={handleFavMenu}
          className={`${styles.iconMenu} me-4`}
        />
      );
    }
  }, [handleFavMenu, params?.id, searching]);

  return (
    <div className={styles.container}>
      {!searching && (
        <div className={styles.containerIconMenu}>
          <MenuIcon className={`${styles.iconMenu} m-0`} onClick={handleShowChooseMenu} />
        </div>
      )}
      <div className={searching ? styles.searching : styles.no_searching}>
        {likeFavorite}
        <Searcher
          searching={searching}
          searchValue={searchValue}
          handleSearchChange={handleSearchChange}
          handleSearch={() => setSearching(!searching)}
        />
      </div>
    </div>
  );
}
export default Header;
